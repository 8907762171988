import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import HowItWorks from "../components/howitworks";
import SkillSet from "../components/skillset";
import WhyUs from "../components/why";
import CTA from "../components/cta";

const Accountants = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: imageSharp(fluid: { originalName: { eq: "accountants-bg.jpg" } }) {
        fluid(quality: 90, maxWidth: 1920) {
          presentationWidth
          presentationHeight
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      mobile: imageSharp(fluid: { originalName: { eq: "account-exec.jpg" } }) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      web: imageSharp(
        fluid: { originalName: { eq: "financial-analyst.jpg" } }
      ) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      backend: imageSharp(
        fluid: { originalName: { eq: "management-accountant.jpg" } }
      ) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      others: imageSharp(
        fluid: { originalName: { eq: "accountant-others.jpg" } }
      ) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  `);

  const heroTitle = (
    <>
      Hire <em className="font-weight-light">Good</em> Accountants
    </>
  );

  const skills = {
    title: "Finding the right skillset",
    sets: [
      {
        title: "Accounting Executives",
        image: data.mobile.fluid,
        list: ["Closing of accounts and preparation of company accounts"]
      },
      {
        title: "Financial Analyst",
        image: data.web.fluid,
        list: [
          "Data analysis and reporting for budgets/forecasts.",
          "Financial planning and reporting"
        ]
      },
      {
        title: "Management Accountant",
        image: data.backend.fluid,
        list: [
          "Management accounting, financial reporting and providing commercial and operational management information"
        ]
      },
      {
        title: "Others",
        image: data.others.fluid,
        list: [
          "Administrative work",
          "Book-keeping",
          "Accounts Payable",
          "Accounts Receivable",
          "Processing Payroll"
        ]
      }
    ]
  };

  const whyUs = {
    title: (
      <>
        <span className="font-weight-normal">Why</span> Choose Us
      </>
    ),
    sets: [
      {
        title: "Dedicated accounting executives",
        desc:
          "Every accounting executives on Tech2Reach is a full-time worker. " +
          "This means they have proven abilities, their time is well-managed, " +
          "and they are dedicated to your company – and your company only."
      },
      {
        title: "Unique talent pool",
        desc:
          "By allowing you to interview and select talent directly, " +
          "Tech2Reach ensures you talented accounting executives are not freelancers."
      },
      {
        title: "Thorough vetting process",
        desc:
          "Every talent on Tech2Reach is handpicked for quality and talent. " +
          "Forget choosing between freelancers – Tech2Reach shows you " +
          "high-quality, verified talent, every time."
      },
      {
        title: "Scale at will",
        desc:
          "Tech2Reach’s model is fine-tuned for quickly growing businesses " +
          "who need longer-term dedicated tech solutions. Leverage the HR and " +
          "management expertise of managing accounting executives and save yourself " +
          "time. Add or subtract accounting executives to your team quickly."
      }
    ]
  };

  return (
    <Layout>
      <SEO
        title="Accountants"
        pathname="/accountants/"
        image={data.hero.fluid}
        keywords={[
          "singapore",
          "hire",
          "accountant",
          "foreign",
          "talent",
          "offshore",
          "outsource",
          "accounting",
          "executives"
        ]}
      />
      <Hero
        background={data.hero.fluid}
        title={heroTitle}
        subTitle="Getting talents couldn't be any easier than this"
        buttonText="Get In Touch"
      />
      <HowItWorks
        title1="No hiring limits"
        desc1="Hire as many foreign talents as you want. Building an offshore accounting team eliminate the issue of getting an EP/SPass."
        title2="Cost savings"
        desc2="Up to 50% cost saving using offshore accounting executives as compared to engaging a local"
        title3="Dedicated talents"
        desc3="A staff fully dedicated to you. Not a freelancer. Doesn't handle other company's projects."
        title4="Don't sweat it"
        desc4="We manage and comply with the local regulations. Payroll, office space, welfare, legal, training, and all other management is taken care of."
      />
      <SkillSet skills={skills} />
      <WhyUs why={whyUs} />
      <CTA />
    </Layout>
  );
};

export default Accountants;
